"use client";
import usePermissionStore from "../store/usePermissionStore";
import { usePermissions } from "../hook/permision";
import { useEffect } from "react";
export default function PermissionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { permissions } = usePermissions(); // Fetch permissions using the custom hook
  const setPermissionData = usePermissionStore(
    (state) => state.setPermissionData
  );

  // Use useEffect to set the permission data only when permissions are available
  useEffect(() => {
    if (permissions) {
      console.log("Fetched permissions:", permissions);
      setPermissionData(permissions);
    }
  }, [permissions, setPermissionData]);

  return <>{children}</>;
}
