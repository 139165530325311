"use client";
import { useQuery } from "@tanstack/react-query";
import { GetUserPermission } from "@/app/api/permission/permission";
import { useSession } from "next-auth/react";
import { PermissionsResponse } from "@/types/rolePermission";
import usePermissionStore from "@/app/store/usePermissionStore";
import { useEffect } from "react";

export const usePermissions = () => {
  const { data: session } = useSession();

  const {
    data: permissions,
    error,
    isLoading,
  } = useQuery<PermissionsResponse>({
    queryKey: ["permissionCodes"],
    queryFn: async () => {
      if (session) {
        const response = await GetUserPermission(session.accessToken);
        console.log("response", response);
        return response.data; // Ensure you're returning the PermissionsResponse format
      }
      return [];
    },

    enabled: !!session,
    retry: 1, // Retry once if the request fails
  });

  return {
    permissions,
    isLoading,
    error,
  };
};
