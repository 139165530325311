import { create } from "zustand";
import { PermissionsResponse } from "@/types/rolePermission";

interface PermissionState {
  permissionData: PermissionsResponse | null;
  setPermissionData: (data: PermissionsResponse) => void;
}

const usePermissionStore = create<PermissionState>((set) => ({
  permissionData: null,

  setPermissionData: (data) => {
    set({ permissionData: data });
  },
}));

export default usePermissionStore;
